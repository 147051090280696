import { axiosInstance as axios } from './axios/axiosInstance';
import config from '../config';


const API_URL = `${config.API_URL}/emisores`;

class EmisorService {
    obtenerTodos() {
        return axios.get(API_URL);
    }

    obtenerPorId(id) {
        return axios.get(`${API_URL}/${id}`);
    }

    guardar(Emisor) {
        return axios.post(API_URL, Emisor);
    }

    actualizar(id, emisor) {
        return axios.put(`${API_URL}/${id}`, emisor);
    }

    eliminar(id) {
        return axios.delete(`${API_URL}/${id}`);
    }
}

export default new EmisorService();