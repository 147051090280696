import React, { useState, useEffect } from 'react';
import ContenedorService from '../services/contenedorService';
import {Link} from "react-router-dom";

const ContenedorList = () => {
    const [contenedores, setContenedores] = useState([]);

    useEffect(() => {
        ContenedorService.obtenerTodos().then(response => {
            setContenedores(response.data);
        }).catch(error => {
            console.error('Error fetching contenedores:', error);
        });
    }, []);

    return (
        <div>
            <h2>Lista de Contenedores</h2>
            <ul>
                {contenedores.map(contenedor => (
                    <li key={contenedor.id}>
                        <Link to={`/contenedores/${contenedor.id}`}>
                            {contenedor.codigo}
                        </Link>
                    </li>
                ))}
            </ul>
            <Link to="/contenedores/nuevo"><button>Crear</button></Link>
        </div>
    );
}

export default ContenedorList;
