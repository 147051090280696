import { axiosInstance as axios } from './axios/axiosInstance';
import config from '../config';

const API_URL = `${config.API_URL}/logout`;

class LogoutService {
    logout() {
        return axios.post(`${API_URL}`)
            .then(response => {
                // Aquí puedes manejar la lógica post logout si es necesario, por ejemplo, limpiar tokens o cerrar sesión.
                return response.data;
            })
            .catch(error => {
                // Manejar errores si es necesario
                console.error('Error during logout:', error);
                throw error;
            });
    }
}

export default new LogoutService();
