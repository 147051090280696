import React, { useState, useEffect } from 'react';
import ReporteService from '../services/reporteService';
import {Link} from "react-router-dom";

const ReporteList = () => {
    const [reportes, setReportes] = useState([]);

    useEffect(() => {
        ReporteService.obtenerTodos().then(response => {
            setReportes(response.data);
        }).catch(error => {
            console.error('Error fetching reportes:', error);
        });
    }, []);

    return (
        <div>
            <h2>Lista de Reportes</h2>
            <ul>
                {reportes.map(reporte => (
                    <li key={reporte.id}>
                        <Link to={`/reportes/${reporte.id}`}>
                            {reporte.fechaReporte} - {reporte.descripcion}
                        </Link>
                    </li>
                ))}
            </ul>
            <Link to="/reportes/nuevo">
                <button>Crear</button>
            </Link>
        </div>
    );
}

export default ReporteList;
