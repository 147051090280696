import { axiosInstance as axios } from './axios/axiosInstance';
import config from '../config';


const API_URL = `${config.API_URL}/facturas`;

class FacturaService {
    obtenerTodos() {
        return axios.get(API_URL);
    }

    obtenerPorId(id) {
        return axios.get(`${API_URL}/${id}`);
    }

    verFacturaCDoc(id) {
        return axios.get(`${API_URL}/${id}/ver`);
    }

    facturar(id, facturaData) {
        return axios.post(`${API_URL}/${id}/facturar`, facturaData);
    }

    obtenerCobranzas(id){
        return axios.get(`${API_URL}/${id}/cobranzas`);
    }

    guardar(factura) {
        return axios.post(API_URL, factura);
    }

    actualizar(id, factura) {
        return axios.put(`${API_URL}/${id}`, factura);
    }

    eliminar(id) {
        return axios.delete(`${API_URL}/${id}`);
    }
}

export default new FacturaService();