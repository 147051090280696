import React, {useState, useEffect} from 'react';
import PresupuestoService from '../services/presupuestoService';
import {useParams, useNavigate} from 'react-router-dom';
import ClienteService from "../services/clienteService";

const PresupuestoForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [presupuesto, setPresupuesto] = useState({
        clienteId: '',
        servicio: 'Alquiler de contenedor refrigerado (reefer) de 40 pies (AR $500.000 + impuestos).',
        transporte: 'No incluido.',
        plazoDeEntrega: 'Una vez confirmada la operación, se acuerda con el cliente la fecha de entrega.',
        valor: '500000',
    });
    const [clientes, setClientes] = useState([]);

    const [error, setError] = useState(false);  // Para manejar el error

    useEffect(() => {
        ClienteService.obtenerTodos().then(response => {
            setClientes(response.data);
        }).catch(error => {
            console.error('Error fetching clientes:', error);
        });

        if (id) {
            PresupuestoService.obtenerPorId(id).then(response => {
                const data = response.data;
                setPresupuesto({
                    ...data,
                    clienteId: data.cliente ? data.cliente.id : "" // Asegurando que clienteId se asigna correctamente
                });
            }).catch(error => {
                console.error('Error fetching presupuesto:', error);
            });
        }
    }, [id]);

    const handleVerCliente = () => {
        if (presupuesto.clienteId) {
            navigate(`/clientes/${presupuesto.clienteId}`);
        } else {
            alert('Por favor, seleccione un cliente primero.');
        }
    };

    const handleCrearCliente = () => {
        navigate(`/clientes/nuevo`);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const presupuestoData = {
            cliente: {id: presupuesto.clienteId},
            servicio: presupuesto.servicio,
            transporte: presupuesto.transporte,
            plazoDeEntrega: presupuesto.plazoDeEntrega,
            valor: presupuesto.valor,
        };

        if (id) {
            PresupuestoService.actualizar(id, presupuestoData).then(() => {
                navigate('/presupuestos');
            }).catch(error => {
                console.error('Error al guardar presupuesto:', error);
            });
        } else {
            PresupuestoService.guardar(presupuestoData).then(() => {
                navigate('/presupuestos');
            }).catch(error => {
                console.error('Error al crear presupuesto:', error);
            });
        }
    };


    const handleVerPresupuesto = async () => {
        if (id)
            navigate(`/presupuestos/${id}/ver`);
        else
            alert("El presupuesto debe ser creado para verse");
    }

    return (
        <div>
            <h2>{id ? 'Editar Presupuesto' : 'Crear Presupuesto'}</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Cliente:</label>
                    <select
                        value={presupuesto.clienteId || ""}
                        onChange={e => setPresupuesto({...presupuesto, clienteId: e.target.value})}
                    >
                        <option value="">Seleccionar</option>
                        {clientes.map(cliente => (
                            <option key={cliente.id} value={cliente.id}>
                                {cliente.nombre} - {cliente.empresa.razonSocial}
                            </option>
                        ))}
                    </select>
                    <button type="button" onClick={handleVerCliente}>Ver</button>
                    <button type="button" onClick={handleCrearCliente}>Crear</button>
                </div>

                <div>
                    <label>Servicio:</label>
                    <textarea
                        value={presupuesto.servicio || ""}
                        onChange={e => setPresupuesto({...presupuesto, servicio: e.target.value})}
                        required
                    />
                </div>
                <div>
                    <label>Transporte:</label>
                    <textarea
                        value={presupuesto.transporte || ""}
                        onChange={e => setPresupuesto({...presupuesto, transporte: e.target.value})}
                        required
                    />
                </div>
                <div>
                    <label>Plazo de entrega:</label>
                    <textarea
                        value={presupuesto.plazoDeEntrega || ""}
                        onChange={e => setPresupuesto({...presupuesto, plazoDeEntrega: e.target.value})}
                        required
                    />
                </div>
                <div>
                    <label>Valor:</label>
                    <input
                        type="text"
                        value={presupuesto.valor || ""}
                        onChange={e => setPresupuesto({...presupuesto, valor: e.target.value})}
                        required
                    />
                </div>
                <button type="submit">Guardar</button>
                <button onClick={handleVerPresupuesto}>
                    <b>Ver presupuesto</b>
                </button>

            </form>
        </div>
    );
}

export default PresupuestoForm;
