import { axiosInstance as axios } from './axios/axiosInstance';
import config from '../config';


const API_URL = `${config.API_URL}/cobranzas`;

class CobranzaService {
    obtenerTodos() {
        return axios.get(API_URL);
    }

    obtenerPorId(id) {
        return axios.get(`${API_URL}/${id}`);
    }

    guardar(cobranza) {
        return axios.post(API_URL, cobranza);
    }

    actualizar(id, cobranza) {
        return axios.put(`${API_URL}/${id}`, cobranza);
    }

    eliminar(id) {
        return axios.delete(`${API_URL}/${id}`);
    }
}

export default new CobranzaService();