import { axiosInstance as axios } from './axios/axiosInstance';
import config from '../config';


const API_URL = `${config.API_URL}/contenedores`;

class ContenedorService {
    obtenerTodos() {
        return axios.get(API_URL);
    }

    obtenerPorId(id) {
        return axios.get(`${API_URL}/${id}`);
    }

    guardar(contenedor) {
        return axios.post(API_URL, contenedor);
    }

    actualizar(id, contenedor) {
        return axios.put(`${API_URL}/${id}`, contenedor);
    }

    eliminar(id) {
        return axios.delete(`${API_URL}/${id}`);
    }

    getReportes(id) {
        return axios.get(`${API_URL}/${id}/reportes`);
    }
}

export default new ContenedorService();