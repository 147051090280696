import React, { useState, useEffect } from 'react';
import CobranzaService from '../services/cobranzaService';
import {Link} from "react-router-dom";

const CobranzaList = () => {
    const [cobranzas, setCobranzas] = useState([]);

    useEffect(() => {
        CobranzaService.obtenerTodos().then(response => {
            setCobranzas(response.data);
        }).catch(error => {
            console.error('Error fetching cobranzas:', error);
        });
    }, []);

    return (
        <div>
            <h2>Lista de Cobranzas</h2>
            <ul>
                {cobranzas.map(cobranza => (
                    <li key={cobranza.id}>
                        <Link to={`/cobranzas/${cobranza.id}`}>
                            {cobranza.montoPagado} - {cobranza.fechaPago}
                        </Link>
                    </li>
                ))}
            </ul>
            <Link to="/cobranzas/nuevo">
                <button>Crear</button>
            </Link>
            <Link to="/cobranzas">
                <button>Cancelar</button>
            </Link>
        </div>
    );
}

export default CobranzaList;
