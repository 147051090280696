import { axiosInstance as axios } from './axios/axiosInstance';
import config from '../config';

const API_URL = `${config.API_URL}/modelosContrato`;

class ReporteService {
    obtenerTodos() {
        return axios.get(API_URL);
    }

    obtenerPorId(id) {
        return axios.get(`${API_URL}/${id}`);
    }

    guardar(reporte) {
        return axios.post(API_URL, reporte);
    }

    actualizar(id, reporte) {
        return axios.put(`${API_URL}/${id}`, reporte);
    }

    eliminar(id) {
        return axios.delete(`${API_URL}/${id}`);
    }
}

export default new ReporteService();