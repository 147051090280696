import axios from 'axios';
import config from '../../config';

const API_URL = `${config.API_URL}`;

const axiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true, // Para que se envíen las cookies automáticamente
});

export { axiosInstance };