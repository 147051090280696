import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';

import AlquilerList from './components/AlquilerList';
import AlquilerForm from './components/AlquilerForm';
import ClienteList from './components/ClienteList';
import ClienteForm from './components/ClienteForm';
import EmpresaList from './components/EmpresaList';
import EmpresaForm from './components/EmpresaForm';
import CobranzaList from './components/CobranzaList';
import CobranzaForm from './components/CobranzaForm';
import ContenedorList from './components/ContenedorList';
import ContenedorForm from './components/ContenedorForm';
import FacturaList from './components/FacturaList';
import FacturaForm from './components/FacturaForm';
import ReporteList from './components/ReporteList';
import ReporteForm from './components/ReporteForm';
import ComentarioList from './components/ComentarioList';
import ComentarioForm from './components/ComentarioForm';
import ModeloContratoList from './components/ModeloContratoList';
import ModeloContratoForm from './components/ModeloContratoForm';
import EmisorForm from "./components/EmisorForm";
import EmisorList from "./components/EmisorList";
import FacturaCDoc from "./components/FacturaCDoc";
import PresupuestoForm from "./components/PresupuestoForm";
import PresupuestoList from "./components/PresupuestoList";
import PresupuestoDoc from "./components/PresupuestoDoc";
import ContratoDoc from "./components/ContratoDoc";
import ProtectedRoute from './components/auth/ProtectedRoute';
import LoginComponent from './components/LoginComponent';
import setupAxiosInterceptors from './services/axios/axiosConfig';
import LogoutService from './services/logoutService';

function App() {
    return (
        <Router>
            <MainAppContent />
        </Router>
    );
}

function MainAppContent() {
    const location = useLocation();
    const navigate = useNavigate();
    const shouldShowNav = !location.pathname.endsWith('/ver');

    useEffect(() => {
        setupAxiosInterceptors(navigate); // Configura el interceptor cuando se monta el componente
    }, [navigate]);

    const handleLogout = async () => {
        try {
            await LogoutService.logout();
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };
    return(
        <>
            {shouldShowNav && (
                <nav>
                    <ul>
                        <li><Link to="/modelosContrato">Modelos de contrato</Link></li>
                        <li><Link to="/alquileres">Alquileres</Link></li>
                        <li><Link to="/clientes">Clientes</Link></li>
                        <li><Link to="/empresas">Empresas</Link></li>
                        <li><Link to="/cobranzas">Cobranzas</Link></li>
                        <li><Link to="/contenedores">Contenedores</Link></li>
                        <li><Link to="/facturas">Facturas</Link></li>
                        <li><Link to="/emisores">Emisores</Link></li>
                        <li><Link to="/reportes">Reportes</Link></li>
                        <li><Link to="/presupuestos">Presupuestos</Link></li>

                        <li>
                            <button onClick={handleLogout}>Logout</button>
                        </li>
                    </ul>
                </nav>
            )}

            <Routes>
                {/* Login */}
                <Route path='/login' element = { <LoginComponent /> }></Route>

                {/* Protege todas las rutas con <ProtectedRoute> */}
                <Route path="*" element={
                    <ProtectedRoute>
                        <Routes>


                            {/* Modelos de contrato Routes */}
                            <Route path="/modelosContrato" element={<ModeloContratoList />} />
                            <Route path="/modelosContrato/nuevo" element={<ModeloContratoForm />} />
                            <Route path="/modelosContrato/:id" element={<ModeloContratoForm />} />

                            {/* Comentario Routes */}
                            <Route path="/comentarios" element={<ComentarioList />} />
                            <Route path="/comentarios/nuevo" element={<ComentarioForm />} />
                            <Route path="/comentarios/:id" element={<ComentarioForm />} />

                            {/* Alquiler Routes */}
                            <Route path="/alquileres" element={<AlquilerList />} />
                            <Route path="/alquileres/nuevo" element={<AlquilerForm />} />
                            <Route path="/alquileres/:id" element={<AlquilerForm />} />
                            <Route path="/alquileres/:id/contrato/:idContrato/ver" element={<ContratoDoc />} />

                            {/* Cliente Routes */}
                            <Route path="/clientes" element={<ClienteList />} />
                            <Route path="/clientes/nuevo" element={<ClienteForm />} />
                            <Route path="/clientes/:id" element={<ClienteForm />} />

                            {/* Empresa Routes */}
                            <Route path="/empresas" element={<EmpresaList />} />
                            <Route path="/empresas/nuevo" element={<EmpresaForm />} />
                            <Route path="/empresas/:id" element={<EmpresaForm />} />

                            {/* Cobranza Routes */}
                            <Route path="/cobranzas" element={<CobranzaList />} />
                            <Route path="/cobranzas/nuevo" element={<CobranzaForm />} />
                            <Route path="/cobranzas/:id" element={<CobranzaForm />} />

                            {/* Contenedor Routes */}
                            <Route path="/contenedores" element={<ContenedorList />} />
                            <Route path="/contenedores/nuevo" element={<ContenedorForm />} />
                            <Route path="/contenedores/:id" element={<ContenedorForm />} />

                            {/* Factura Routes */}
                            <Route path="/facturas" element={<FacturaList />} />
                            <Route path="/facturas/nuevo" element={<FacturaForm />} />
                            <Route path="/facturas/nuevo/alquiler/:idAlquiler" element={<FacturaForm />} />
                            <Route path="/facturas/:id" element={<FacturaForm />} />
                            <Route path="/facturas/:id/ver" element={<FacturaCDoc />} />

                            {/* Reporte Routes */}
                            <Route path="/reportes" element={<ReporteList />} />
                            <Route path="/reportes/nuevo" element={<ReporteForm />} />
                            <Route path="/reportes/:id" element={<ReporteForm />} />

                            {/* Emisor Routes */}
                            <Route path="/emisores" element={<EmisorList />} />
                            <Route path="/emisores/nuevo" element={<EmisorForm />} />
                            <Route path="/emisores/:id" element={<EmisorForm />} />

                            {/* Presupuesto Routes */}
                            <Route path="/presupuestos" element={<PresupuestoList />} />
                            <Route path="/presupuestos/nuevo" element={<PresupuestoForm />} />
                            <Route path="/presupuestos/:id" element={<PresupuestoForm />} />
                            <Route path="/presupuestos/:id/ver" element={<PresupuestoDoc />} />
                        </Routes>
                    </ProtectedRoute>
                } />
            </Routes>
        </>
    );
}

export default App;
