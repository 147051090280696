import React, {useState, useEffect} from 'react';
import ClienteService from '../services/clienteService';
import {useParams, useNavigate} from 'react-router-dom';
import EmisorService from "../services/emisorService";
import EmpresaService from "../services/empresaService";

const ClienteForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [cliente, setCliente] = useState({nombre: '', cuit: '', direccion: '', telefono: '', empresa: ''});
    const [empresas, setEmpresas] = useState([]);
    const [datosEmpresa, setDatosEmpresa] = useState(null);  // Para almacenar los datos obtenidos
    const [error, setError] = useState(false);  // Para manejar el error

    useEffect(() => {

        EmpresaService.obtenerTodos().then(response => {
            setEmpresas(response.data);
        }).catch(error => {
            console.error('Error fetching empresas:', error);
        });

        if (id) {
            ClienteService.obtenerPorId(id).then(response => {
                setCliente(response.data);
            }).catch(error => {
                console.error('Error fetching cliente:', error);
            });
        }
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const clienteData = {
            nombre: cliente.nombre,
            cuit: cliente.cuit,
            direccion: cliente.direccion,
            telefono: cliente.telefono,
            email: cliente.email,
            empresa: {id: cliente.empresaId}
        }
        if (id) {
            ClienteService.actualizar(id, clienteData).then(() => {
                navigate('/clientes');
            }).catch(error => {
                console.error('Error al guardar cliente:', error);
            });
        } else {
            ClienteService.guardar(clienteData).then(() => {
                navigate('/clientes');
            }).catch(error => {
                console.error('Error al crear cliente:', error);
            });
        }
    }

    const handleChange = async (e) => {
        const nuevoCuit = e.target.value;
        // Validar si tiene 11 dígitos
        if (nuevoCuit.length === 11) {
            try {
                const response = await fetch(
                    `https://script.google.com/macros/s/AKfycbzOXBc2FOcGWZi6G_uZnNwhpdHMNYJtQCsPXw0TGsrf7QwgBHdcjkXI8ylQPLvkcQ/exec?cuit=${nuevoCuit}`
                );
                const jsonData = await response.json();

                // Verificar si se obtuvieron datos
                if (jsonData && Object.keys(jsonData).length > 0) {
                    setDatosEmpresa(jsonData);
                    setError(false);  // No hay error, datos obtenidos correctamente
                } else {
                    setDatosEmpresa(null);  // Limpiar los datos si no hay datos válidos
                    setError(true);  // Mostrar mensaje de error
                }
            } catch (error) {
                setDatosEmpresa(null);  // Limpiar datos en caso de error
                setError(true);  // Indicar que ocurrió un error
            }
        } else {
            setDatosEmpresa(null);  // Limpiar los datos si el CUIT es inválido
            setError(false);  // No hay error si aún no hay 11 dígitos
        }
    };

    const handleDelete = () => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este cliente?')) {
            EmisorService.eliminar(id).then(() => {
                navigate('/clientes');
            }).catch(error => {
                console.error('Error al eliminar el cliente:', error);
            });
        }
    }

    const handleVerEmpresa = () => {
        if (cliente.empresaId) {
            navigate(`/empresas/${cliente.empresaId}`);
        } else {
            alert('Por favor, primero seleccione una empresa.');
        }
    };

    return (
        <div>
            <h2>{id ? 'Editar Cliente' : 'Crear Cliente'}</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Nombre:</label>
                    <input
                        type="text"
                        value={cliente.nombre}
                        onChange={e => setCliente({...cliente, nombre: e.target.value})}
                        required
                    />
                </div>

                <div>
                    <label>CUIT:</label>
                    <input
                        type="text"
                        value={cliente.cuit}
                        onChange={e => setCliente({...cliente, cuit: e.target.value})}
                        required
                    />
                </div>

                <div>
                    <label>Dirección:</label>
                    <input
                        type="text"
                        value={cliente.direccion}
                        onChange={e => setCliente({...cliente, direccion: e.target.value})}
                        required
                    />
                </div>

                <div>
                    <label>Teléfono:</label>
                    <input
                        type="text"
                        value={cliente.telefono}
                        onChange={e => setCliente({...cliente, telefono: e.target.value})}
                        required
                    />
                </div>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={cliente.email}
                        onChange={e => setCliente({...cliente, email: e.target.value})}
                        required
                    />
                </div>
                <div>
                    <label>Empresa:</label>
                    <select
                        value={cliente.empresa.id || ""}
                        onChange={e => setCliente({...cliente, empresaId: e.target.value})}
                    >
                        <option value="">Seleccionar</option>
                        {empresas.map(empresa => (
                            <option key={empresa.id} value={empresa.id}>
                                {empresa.razonSocial}
                            </option>
                        ))}
                    </select>
                    <button type="button" onClick={handleVerEmpresa}>Ver</button>
                </div>

                <div id="infoEmpresa">
                    {datosEmpresa && (
                        <div>
                            <h3>Datos de la Empresa:</h3>
                            <p><strong>Razón Social:</strong> {datosEmpresa.razonSocial}</p>
                            <p><strong>Dirección:</strong> {datosEmpresa.direccion}</p>
                            {/* Puedes agregar más campos según el JSON que recibas */}
                        </div>
                    )}
                    {error && (
                        <div>
                            <p>No se pudieron obtener datos</p>
                        </div>
                    )}
                </div>

                <button type="submit">Guardar</button>
                {id && (
                    <button type="button" onClick={handleDelete} style={{backgroundColor: 'red', color: 'white'}}>
                        Eliminar
                    </button>
                )}
            </form>
        </div>
    );
}

export default ClienteForm;
