import { axiosInstance as axios } from './axios/axiosInstance';
import config from '../config';


const API_URL = `${config.API_URL}/empresas`;

class EmpresaService {
    obtenerTodos() {
        return axios.get(API_URL);
    }

    obtenerPorId(id) {
        return axios.get(`${API_URL}/${id}`);
    }

    guardar(Empresa) {
        return axios.post(API_URL, Empresa);
    }

    actualizar(id, empresa) {
        return axios.put(`${API_URL}/${id}`, empresa);
    }

    eliminar(id) {
        return axios.delete(`${API_URL}/${id}`);
    }
}

export default new EmpresaService();