import React, { useState, useEffect } from 'react';
import FacturaService from '../services/facturaService';
import AlquilerService from '../services/alquilerService';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import CobranzaService from "../services/cobranzaService";

const FacturaForm = () => {
    const { id, idAlquiler } = useParams();
    const navigate = useNavigate();
    const location = useLocation(); // Obtener la ubicación actual
    const [factura, setFactura] = useState({ fechaEmision: '', importeNeto: '', comentarios: '', alquilerId: '' });
    const [alquileres, setAlquileres] = useState([]);
    const [cobranzas, setCobranzas] = useState([]);

    useEffect(() => {
        // Cargar la lista de alquileres
        AlquilerService.obtenerTodos().then(response => {
            setAlquileres(response.data);
        }).catch(error => {
            console.error('Error fetching alquileres:', error);
        });

        if (id) {
            FacturaService.obtenerPorId(id).then(response => {
                setFactura({
                    ...response.data,
                    alquilerId: response.data.alquiler ? response.data.alquiler.id : ''
                });
            }).catch(error => {
                console.error('Error fetching factura:', error);
            });

            FacturaService.obtenerCobranzas(id).then(response => {
                setCobranzas(response.data);
            }).catch(error => {
                console.error('Error fetching cobranzas:', error);
            });
        } else if (idAlquiler) {
            setFactura(prev => ({ ...prev, alquilerId: idAlquiler }));
        }
    }, [id, idAlquiler]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const facturaData = {
            alquilerId: factura.alquilerId,
            fechaEmision: factura.fechaEmision,
            importeNeto: factura.importeNeto,
            comentarios: factura.comentarios,
        };

        if (id) {
            FacturaService.actualizar(id, facturaData).then(() => {
                navigate('/facturas');
            }).catch(error => {
                console.error('Error al guardar factura:', error);
            });
        } else {
            FacturaService.guardar(facturaData).then(() => {
                navigate('/facturas');
            }).catch(error => {
                console.error('Error al crear factura:', error);
            });
        }
    }

    const handleVerAlquiler = () => {
        if (factura.alquilerId) {
            navigate(`/alquileres/${factura.alquilerId}`);
        } else {
            alert('Por favor, seleccione un alquiler primero.');
        }
    };

    const handleVerCobranza = (cobranzaId) => {
        if (cobranzaId) {
            navigate(`/cobranzas/${cobranzaId}`);
        }
    };

    const handleFacturar = async () => {
        try {
            const facturaData = {
                importeNeto: factura.importeNeto,
                fechaEmision: factura.fechaEmision || new Date().toISOString(), // Si no hay fecha, usar la actual
                comentarios: factura.comentarios,
            };

            await FacturaService.facturar(id, facturaData);
            alert('Factura generada con éxito.');
            navigate(`/facturas/${id}/ver`);
        } catch (error) {
            alert('Error al generar la factura: ' + error.response?.data?.message || 'Error desconocido');
        }
    };

    return (
        <div>
            <h2>{id ? 'Editar Factura' : 'Crear Factura'}</h2>
            {id && (
                <div>
                    {factura.isFacturado ? (
                        <>
                            <p style={{ color: 'greenyellow' }}>FACTURA GENERADA</p>
                            <Link to={`${location.pathname}/ver`}>
                                <button>Ver factura realizada</button>
                            </Link>
                        </>
                    ) : (
                        <>
                            <p style={{color: 'red'}}>SIN FACTURAR</p>
                            <Link to={`${location.pathname}/ver`}>
                                <button>Ver cómo quedará la factura</button>
                            </Link>
                            <button onClick={handleFacturar}>
                                <b>Facturar</b>
                            </button>
                        </>
                    )}
                </div>
            )}

            <form onSubmit={handleSubmit}>
                <div>
                <label>Fecha emisión (si no se completa, se hace de "ahora"):</label>
                    <input
                        type="date"
                        value={factura.fechaEmision ? factura.fechaEmision.split('T')[0] : ''}
                        onChange={e => setFactura({ ...factura, fechaEmision: e.target.value })}
                    />
                </div>
                <div>
                    <label>Importe Neto:</label>
                    <input
                        type="number"
                        value={factura.importeNeto}
                        onChange={e => setFactura({ ...factura, importeNeto: parseFloat(e.target.value) })}
                        required
                    />
                </div>
                <div>
                    <label>Comentarios:</label>
                    <input
                        type="text"
                        value={factura.comentarios}
                        onChange={e => setFactura({ ...factura, comentarios: e.target.value })}
                    />
                </div>
                <div>
                    <label>Alquiler:</label>
                    <select
                        value={factura.alquilerId || idAlquiler || ""}
                        onChange={e => setFactura({...factura, alquilerId: e.target.value})}
                        disabled={!!id} // Desactiva el select si 'id' tiene un valor
                    >

                        <option value="">Seleccione un Alquiler</option>
                        {alquileres.map(alquiler => (
                            <option key={alquiler.id} value={alquiler.id}>
                                {alquiler.cliente.nombre} - {alquiler.contenedor.codigo}
                            </option>
                        ))}
                    </select>
                    <button type="button" onClick={handleVerAlquiler}>Ver</button>
                </div>
                <button type="submit">Guardar</button>
                {id && (
                    <button type="button" onClick={() => navigate(`/facturas/${id}/ver`)}>Ver</button>
                )}
                {id && (
                    <div>
                        <h3>Cobranzas</h3>
                        <ul>
                            {cobranzas.map(cobranza => (
                                <button key={cobranza.id} onClick={() => handleVerCobranza(cobranza.id)}>
                                    {new Date(cobranza.fechaPago).toLocaleString('es-ES', {
                                        weekday: 'short',
                                        day: 'numeric',
                                        month: 'short'
                                    })}&nbsp;
                                    {new Intl.NumberFormat('es-AR', {
                                        style: 'currency',
                                        currency: 'ARS'
                                    }).format(cobranza.montoPagado)}
                                </button>
                            ))}
                        </ul>
                    </div>
                )}
            </form>
        </div>
    );
}

export default FacturaForm;
