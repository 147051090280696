// EmpresaList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EmpresaService from '../services/empresaService';

const EmpresaList = () => {
  const [empresas, setEmpresas] = useState([]);
  const [cuitSeleccionado, setCuitSeleccionado] = useState(null);

  useEffect(() => {
    EmpresaService.obtenerTodos().then(response => {
      setEmpresas(response.data);
    }).catch(error => {
      console.error('Error fetching empresas:', error);
    });
  }, []);


  return (
    <div>
      <h2>Lista de Empresas</h2>
      <ul>
        {empresas.map(empresa => (
            <li key={empresa.id}>
                <Link to={`/empresas/${empresa.id}`}>
                    {empresa.razonSocial}
                </Link>
            </li>
        ))}
      </ul>
        <Link to="/empresas/nuevo">
            <button>Crear</button>
        </Link>
    </div>
  );
}

export default EmpresaList;
