import React, {useState, useEffect} from 'react';
import AlquilerService from '../services/alquilerService';
import ClienteService from '../services/clienteService';
import EmisorService from '../services/emisorService';
import ContenedorService from '../services/contenedorService';
import ModeloContratoService from '../services/modeloContratoService';
import {useParams, useNavigate, Link} from 'react-router-dom';

const AlquilerForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [alquiler, setAlquiler] = useState({
        clienteId: '',
        contenedorId: '',
        emisorId: '',
        fechaInicio: '',
        fechaFin: '',
        ubicacion: '',
        cancelado: '',
        facturable: '',
        facturaAutomatica: '',
        fechaCancelacion: '',
        montoInicial: ''
    });

    const [clientes, setClientes] = useState([]);
    const [emisores, setEmisores] = useState([]);
    const [contenedores, setContenedores] = useState([]);
    const [facturas, setFacturas] = useState([]);
    const [modelosContrato, setModelosContrato] = useState([]);
    const [modeloElegido, setModeloElegido] = useState([]);

    useEffect(() => {
        // Cargar lista de clientes
        ClienteService.obtenerTodos().then(response => {
            setClientes(response.data);
        }).catch(error => {
            console.error('Error fetching clients:', error);
        });

        // Cargar lista de emisores
        EmisorService.obtenerTodos().then(response => {
            setEmisores(response.data);
        }).catch(error => {
            console.error('Error fetching emisores:', error);
        });

        // Cargar lista de contenedores
        ContenedorService.obtenerTodos().then(response => {
            setContenedores(response.data);
        }).catch(error => {
            console.error('Error fetching containers:', error);
        });

        //Lista de facturas del alquiler
        AlquilerService.obtenerFacturas(id).then(response => {
            setFacturas(response.data);
        }).catch(error => {
            console.error('Error fetching facturas:', error);
        })

        ModeloContratoService.obtenerTodos().then(response => {
            setModelosContrato(response.data);
        }).catch(error => {
            console.error('Error fetching modelosContrato:', error);
        });

        // Si existe un ID, cargar el alquiler para editarlo
        if (id) {
            AlquilerService.obtenerPorId(id).then(response => {
                setAlquiler({
                    id: response.data.id,
                    clienteId: response.data.cliente.id,
                    contenedorId: response.data.contenedor.id,
                    emisorId: response.data.emisor.id,
                    fechaInicio: response.data.fechaInicio,
                    fechaFin: response.data.fechaFin,
                    ubicacion: response.data.ubicacion,
                    //cancelado: response.data.cancelado,
                    facturable: response.data.facturable,
                    facturaAutomatica: response.data.facturaAutomatica,
                    fechaCancelacion: alquiler.fechaCancelacion,
                    montoInicial: response.data.montoInicial,
                });
            }).catch(error => {
                console.error('Error fetching alquiler:', error);
            });
        }
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const alquilerData = {
            cliente: {id: alquiler.clienteId},
            contenedor: {id: alquiler.contenedorId},
            emisor: {id: alquiler.emisorId},
            fechaInicio: alquiler.fechaInicio,
            fechaFin: alquiler.fechaFin,
            ubicacion: alquiler.ubicacion,
            cancelado: alquiler.cancelado,
            facturable: alquiler.facturable,
            facturaAutomatica: alquiler.facturaAutomatica,
            fechaCancelacion: alquiler.fechaCancelacion,
            montoInicial: alquiler.montoInicial,
        };
        if (id) {
            AlquilerService.actualizar(id, alquilerData).then(() => {
                navigate('/alquileres');
            }).catch(error => {
                console.error('Error saving alquiler:', error);
            });
        } else {
            AlquilerService.guardar(alquilerData).then(() => {
                navigate('/alquileres');
            }).catch(error => {
                console.error('Error saving alquiler:', error);
            });
        }

    };

    const handleVerCliente = () => {
        if (alquiler.clienteId) {
            navigate(`/clientes/${alquiler.clienteId}`);
        } else {
            alert('Por favor, seleccione un cliente primero.');
        }
    };

    const handleVerContenedor = () => {
        if (alquiler.contenedorId) {
            navigate(`/contenedores/${alquiler.contenedorId}`);
        } else {
            alert('Por favor, seleccione un contenedor primero.');
        }
    };

    const handleVerFactura = (facturaId) => {
        if (id && facturaId) {
            navigate(`/facturas/${facturaId}`);
        }
    };

    const handleVerEmisor = (emisorId) => {
        if (id && emisorId) {
            navigate(`/emisores/${emisorId}`);
        }
    };

    const handleVerUbicacionGoogleMaps = () => {
        if (alquiler.ubicacion) {
            window.location.href = `https://www.google.com/maps/search/${alquiler.ubicacion}`;
        } else {
            alert('Sin ubicación');
        }
    };

    const handleGenerarContrato = () => {
        if (modeloElegido.id) {
            AlquilerService.verContrato(id, modeloElegido.id)
                .then(() => {
                    // Redirige a la ruta que muestra el contrato
                    navigate(`/alquileres/${id}/contrato/${modeloElegido.id}/ver`);
                })
                .catch(error => {
                    console.error('Error al generar contrato:', error);
                });
        } else {
            alert('Elegí un modelo');
        }
    };

    const handleNuevaFactura = () => {
        if (id) {
            navigate(`/facturas/nuevo/alquiler/${alquiler.id}`);
        }
    };

    const handleEditarContrato = () => {
        if (modeloElegido.id) {
            navigate(`/modelosContrato/${modeloElegido.id}`);
        } else {
            alert('Elegí un modelo');
        }

    };

    return (
        <div>
            <h2>{id ? 'Editar Alquiler' : 'Nuevo Alquiler'}</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Cliente:</label>
                    <select
                        value={alquiler.clienteId}
                        onChange={e => setAlquiler({...alquiler, clienteId: e.target.value})}
                    >
                        <option value="">Seleccione un Cliente</option>
                        {clientes.map(cliente => (
                            <option key={cliente.id} value={cliente.id}>
                                {cliente.nombre} {cliente.empresa && cliente.empresa.razonSocial ? `de (${cliente.empresa.razonSocial})` : ''}
                            </option>
                        ))}

                    </select>
                    <button type="button" onClick={handleVerCliente}>Ver</button>
                </div>
                <div>
                    <label>Contenedor:</label>
                    <select
                        value={alquiler.contenedorId}
                        onChange={e => setAlquiler({...alquiler, contenedorId: e.target.value})}>
                        <option value="">Seleccione un Contenedor</option>
                        {contenedores.map(contenedor => (
                            <option key={contenedor.id} value={contenedor.id}>
                                {contenedor.codigo}
                            </option>
                        ))}
                    </select>
                    <button type="button" onClick={handleVerContenedor}>Ver</button>
                </div>
                <div>
                    <label>Fecha de Inicio:</label>
                    <input
                        type="date"
                        value={alquiler.fechaInicio}
                        onChange={e => setAlquiler({...alquiler, fechaInicio: e.target.value})}
                    />
                </div>
                <div>
                    <label>Fecha de Fin:</label>
                    <input
                        type="date"
                        value={alquiler.fechaFin}
                        onChange={e => setAlquiler({...alquiler, fechaFin: e.target.value})}
                    />
                </div>
                <div>
                    <label>Ubicacion:</label>
                    <input
                        type="text"
                        value={alquiler.ubicacion}
                        onChange={e => setAlquiler({...alquiler, ubicacion: e.target.value})}
                    />
                    <button type="button" onClick={handleVerUbicacionGoogleMaps}>Ver</button>
                </div>
                <div>
                    <label>Monto inicial:</label>
                    <input
                        type="text"
                        value={alquiler.montoInicial}
                        onChange={e => setAlquiler({...alquiler, montoInicial: e.target.value})}
                    />
                </div>
                <div>
                    <label>Cancelado/terminado:</label>
                    <input
                        type="checkbox"
                        checked={alquiler.cancelado || false}
                        onChange={e => setAlquiler({...alquiler, cancelado: e.target.checked})}
                    />
                </div>
                <div>
                    <label>Con factura:</label>
                    <input
                        type="checkbox"
                        checked={alquiler.facturable || false}
                        onChange={e => setAlquiler({...alquiler, facturable: e.target.checked})}
                    />
                </div>
                <div>
                    <label>Factura automática:</label>
                    <input
                        type="checkbox"
                        checked={alquiler.facturaAutomatica || false}
                        onChange={e => setAlquiler({...alquiler, facturaAutomatica: e.target.checked})}
                    />
                </div>
                <div>
                    <label>Emisor de factura:</label>
                    <select
                        value={alquiler.emisorId}
                        onChange={e => setAlquiler({...alquiler, emisorId: e.target.value})}>
                        <option value="">Seleccione un emisor</option>
                        {emisores.map(emisor => (
                            <option key={emisor.id} value={emisor.id}>
                                {emisor.razonSocial}
                            </option>
                        ))}
                    </select>
                    <button type="button" onClick={handleVerEmisor}>Ver</button>
                </div>
                <button type="submit">Guardar</button>
            </form>
            {id && (
                <div>
                    <h3>Modelo de contrato</h3>
                    <select
                        onChange={e => setModeloElegido({...modeloElegido, id: e.target.value})}>
                        <option value="">Seleccione un modelo</option>
                        {modelosContrato.map(modeloContrato => (
                            <option key={modeloContrato.id} value={modeloContrato.id}>
                                {modeloContrato.titulo}
                            </option>
                        ))}
                    </select>
                    <button type="button" onClick={handleGenerarContrato}>Armar contrato</button>
                    <button type="button" onClick={handleEditarContrato}>Editar</button>

                    <h3>Facturas</h3>
                    <button onClick={handleNuevaFactura}>Crear factura</button>
                    <ul>
                        {facturas.map(factura => (
                            <button key={factura.id} onClick={() => handleVerFactura(factura.id)}>
                                {new Date(factura.fechaEmision).toLocaleString('es-ES', {month: 'long'})}&nbsp;
                                {new Intl.NumberFormat('es-AR', {
                                    style: 'currency',
                                    currency: 'ARS'
                                }).format(factura.monto)}
                            </button>
                        ))}
                    </ul>


                </div>
            )}
        </div>

    );
}

export default AlquilerForm;
