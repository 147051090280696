import { axiosInstance as axios } from './axios/axiosInstance';
import config from '../config';

const API_URL = `${config.API_URL}/clientes`;

class ClienteService {
    obtenerTodos() {
        return axios.get(API_URL);
    }

    obtenerPorId(id) {
        return axios.get(`${API_URL}/${id}`);
    }

    guardar(Cliente) {
        return axios.post(API_URL, Cliente);
    }

    actualizar(id, cliente) {
        return axios.put(`${API_URL}/${id}`, cliente);
    }

    eliminar(id) {
        return axios.delete(`${API_URL}/${id}`);
    }
}

export default new ClienteService();