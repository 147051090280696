import React, { useState, useEffect } from 'react';
import EmpresaService from '../services/empresaService';
import { useParams, useNavigate } from 'react-router-dom';

const EmpresaForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [empresa, setEmpresa] = useState({ razonSocial: '', direccion: '', cuit: '' });
    const [datosEmpresa, setDatosEmpresa] = useState(null);  // Para almacenar los datos obtenidos
    const [error, setError] = useState(false);  // Para manejar el error

    useEffect(() => {
        if (id) {
            EmpresaService.obtenerPorId(id).then(response => {
                setEmpresa(response.data);
            }).catch(error => {
                console.error('Error fetching empresa:', error);
            });
        }
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if(id){
            EmpresaService.actualizar(id, empresa).then(() => {
                navigate('/empresas');
            }).catch(error => {
                console.error('Error al guardar empresa:', error);
            });
        }else{
            EmpresaService.guardar(empresa).then(() => {
                navigate('/empresas');
            }).catch(error => {
                console.error('Error al crear empresa:', error);
            });
        }
    }

    return (
        <div>
            <h2>{id ? 'Editar Empresa' : 'Crear Empresa'}</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Razon social:</label>
                    <input
                        type="text"
                        value={empresa.razonSocial}
                        onChange={e => setEmpresa({ ...empresa, razonSocial: e.target.value })}
                        required
                    />
                </div>
                <div>
                    <label>Dirección:</label>
                    <input
                        type="text"
                        value={empresa.direccion}
                        onChange={e => setEmpresa({ ...empresa, direccion: e.target.value })}
                        required
                    />
                </div>
                <div>
                    <label>CUIT:</label>
                    <input
                        type="text"
                        value={empresa.cuit}
                        onChange={e => setEmpresa({ ...empresa, cuit: e.target.value })}
                    />
                </div>

                <button type="submit">Guardar</button>

            </form>
        </div>
    );
}

export default EmpresaForm;
