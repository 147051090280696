import React, { useState, useEffect } from 'react';
import ComentarioService from '../services/comentarioService';
import { useParams, useNavigate } from 'react-router-dom';

const ComentarioForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [comentario, setComentario] = useState({ codigo: '', capacidad: '', ubicacion: '' });

    useEffect(() => {
        if (id) {
            ComentarioService.obtenerPorId(id).then(response => {
                setComentario(response.data);
            }).catch(error => {
                console.error('Error fetching comentario:', error);
            });
        }
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if(id){
            ComentarioService.actualizar(id, comentario).then(() => {
                navigate('/comentarios');
            }).catch(error => {
                console.error('Error saving comentario:', error);
            });
        }else{
            ComentarioService.guardar(comentario).then(() => {
                navigate('/comentarios');
            }).catch(error => {
                console.error('Error al guardar el comentario:', error);
            });
        }


    }

    return (
        <div>
            <h2>{id ? 'Editar Comentario' : 'Crear Comentario'}</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Código:</label>
                    <input
                        type="text"
                        value={comentario.codigo}
                        onChange={e => setComentario({ ...comentario, codigo: e.target.value })}
                    />
                </div>
                <div>
                    <label>Capacidad:</label>
                    <input
                        type="number"
                        value={comentario.capacidad}
                        onChange={e => setComentario({ ...comentario, capacidad: e.target.value })}
                    />
                </div>
                <div>
                    <label>Ubicación:</label>
                    <input
                        type="text"
                        value={comentario.ubicacion}
                        onChange={e => setComentario({ ...comentario, ubicacion: e.target.value })}
                    />
                </div>
                <button type="submit">Guardar</button>
            </form>
        </div>
    );
}

export default ComentarioForm;
