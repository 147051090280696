import React, { useState, useEffect } from 'react';
import ModeloContratoService from '../services/modeloContratoService';
import {Link} from "react-router-dom";

const ModeloContratoList = () => {
    const [modeloContratos, setModeloContratos] = useState([]);

    useEffect(() => {
        ModeloContratoService.obtenerTodos().then(response => {
            setModeloContratos(response.data);
        }).catch(error => {
            console.error('Error al obtener el modelo de contrato:', error);
        });
    }, []);

    return (
        <div>
            <h2>Lista de modelos de contrato</h2>
            <ul>
                {modeloContratos.map(modeloContrato => (
                    <li key={modeloContrato.id}>
                        <Link to={`/modelosContrato/${modeloContrato.id}`}>
                            {modeloContrato.titulo}
                        </Link>
                    </li>
                ))}
            </ul>
            <Link to="/modelosContrato/nuevo">
                <button>Crear</button>
            </Link>
        </div>
    );
}

export default ModeloContratoList;
