import React, { useState, useEffect } from 'react';
import EmisorService from '../services/emisorService';
import { useParams, useNavigate } from 'react-router-dom';

const EmisorForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [emisor, setEmisor] = useState({ codigo: '', capacidad: '', ubicacion: '' });

    useEffect(() => {
        if (id) {
            EmisorService.obtenerPorId(id).then(response => {
                setEmisor(response.data);
            }).catch(error => {
                console.error('Error fetching emisor:', error);
            });
        }
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if(id){
            EmisorService.actualizar(id, emisor).then(() => {
                navigate('/emisores');
            }).catch(error => {
                console.error('Error saving emisor:', error);
            });
        }else{
            EmisorService.guardar(emisor).then(() => {
                navigate('/emisores');
            }).catch(error => {
                console.error('Error al guardar el emisor:', error);
            });
        }
    }

    const handleDelete = () => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este emisor?')) {
            EmisorService.eliminar(id).then(() => {
                navigate('/emisores');
            }).catch(error => {
                console.error('Error al eliminar el emisor:', error);
            });
        }
    }

    return (
        <div>
            <h2>{id ? 'Editar Emisor' : 'Crear Emisor'}</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Razón social:</label>
                    <input
                        type="text"
                        value={emisor.razonSocial}
                        onChange={e => setEmisor({...emisor, razonSocial: e.target.value})}
                    />
                </div>
                <div>
                    <label>CUIT:</label>
                    <input
                        type="text"
                        value={emisor.cuit}
                        onChange={e => setEmisor({...emisor, cuit: e.target.value})}
                    />
                </div>
                <div>
                    <label>Dirección:</label>
                    <input
                        type="text"
                        value={emisor.direccion}
                        onChange={e => setEmisor({...emisor, direccion: e.target.value})}
                    />
                </div>
                <div>
                    <label>Punto de venta:</label>
                    <input
                        type="text"
                        value={emisor.puntoVenta}
                        onChange={e => setEmisor({...emisor, puntoVenta: e.target.value})}
                    />
                </div>
                <div>
                    <label>Condición:</label>
                    <input
                        type="text"
                        value={emisor.condicion}
                        onChange={e => setEmisor({...emisor, condicion: e.target.value})}
                    />
                </div>
                <button type="submit">Guardar</button>
                {id && (
                    <button type="button" onClick={handleDelete} style={{backgroundColor: 'red', color: 'white'}}>
                        Eliminar
                    </button>
                )}
            </form>
        </div>
    );
}

export default EmisorForm;
