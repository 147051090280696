// ClienteList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ClienteService from '../services/clienteService';

const ClienteList = () => {
  const [clientes, setClientes] = useState([]);
  const [cuitSeleccionado, setCuitSeleccionado] = useState(null);

  useEffect(() => {
    ClienteService.obtenerTodos().then(response => {
      setClientes(response.data);
    }).catch(error => {
      console.error('Error fetching clientes:', error);
    });
  }, []);


  return (
    <div>
      <h2>Lista de Clientes</h2>
      <ul>
        {clientes.map(cliente => (
            <li key={cliente.id}>
                <Link to={`/clientes/${cliente.id}`}>
                    {cliente.nombre}
                </Link>
            </li>
        ))}
      </ul>
        <Link to="/clientes/nuevo">
            <button>Crear</button>
        </Link>
    </div>
  );
}

export default ClienteList;
