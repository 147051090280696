import React, {useState, useEffect} from 'react';
import FacturaService from '../services/facturaService';
import {Link} from "react-router-dom";

const FacturaList = () => {
    const [facturas, setFacturas] = useState([]);

    useEffect(() => {
        FacturaService.obtenerTodos().then(response => {

            setFacturas(response.data);
        }).catch(error => {
            console.error('Error fetching facturas:', error);
        });
    }, []);

    return (
        <div>
            <h2>Lista de Facturas</h2>
            <ul>
                {facturas.map(factura => (
                    <li key={factura.id}>
                        <Link to={`/facturas/${factura.id}`}>
                            {factura.fechaEmision} - {factura.monto}
                        </Link>
                    </li>
                ))}
            </ul>

            <Link to="/facturas/nuevo">
                <button>Crear</button>
            </Link>
        </div>
    );
}

export default FacturaList;
