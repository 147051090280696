import { axiosInstance as axios } from './axios/axiosInstance';
import config from '../config';

const API_URL = `${config.API_URL}/presupuestos`;

class PresupuestoService {
    obtenerTodos() {
        return axios.get(API_URL);
    }

    obtenerPorId(id) {
        return axios.get(`${API_URL}/${id}`);
    }

    verPresupuestoDoc(id) {
        return axios.get(`${API_URL}/${id}/ver`);
    }

    guardar(presupuesto) {
        return axios.post(API_URL, presupuesto);
    }

    actualizar(id, presupuesto) {
        return axios.put(`${API_URL}/${id}`, presupuesto);
    }

    eliminar(id) {
        return axios.delete(`${API_URL}/${id}`);
    }
}

export default new PresupuestoService();