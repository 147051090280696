import { axiosInstance as axios } from './axios/axiosInstance';
import config from '../config';

const API_URL = `${config.API_URL}/alquileres`;

class AlquilerService {
    obtenerTodos() {
        return axios.get(API_URL);
    }

    obtenerPorId(id) {
        return axios.get(`${API_URL}/${id}`);
    }

    guardar(alquiler) {
        return axios.post(API_URL, alquiler);
    }

    actualizar(id, alquiler) {
        return axios.put(`${API_URL}/${id}`, alquiler);
    }

    eliminar(id) {
        return axios.delete(`${API_URL}/${id}`);
    }

    obtenerFacturas(id){
        return axios.get(`${API_URL}/${id}/facturas`);
    }

    verContrato(id, contratoId) {
        return axios.get(`${API_URL}/${id}/contrato/${contratoId}/ver`);
    }
}

export default new AlquilerService();
